import React from 'react';
import { enquireScreen } from 'enquire-js';

import './CommonPage.less';


let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});


class PrivacyAgreement extends React.PureComponent {
  state = {
    isMobile,
    // isScroll: false,
  };

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
    // window.addEventListener('scroll', this.bindScroll);
    document.documentElement.scrollTop = document.body.scrollTop = 0;
  }



  render() {
    return (



<div className={'contentWrapper'}>
<h1>Aviso de Privacidad</h1>
<p>
Estimado cliente, aquí le presento una propuesta de traducción al español peruano de su aviso de privacidad:</p><p>
</p><p>
Aviso de Privacidad</p><p>
</p><p>
Nos importa la privacidad de nuestros clientes, por eso este Aviso de Privacidad (el "Aviso") tiene el propósito de informarle cómo Peru S.A.C., con RUC 20606720350, regula el tratamiento de la información y datos personales de los usuarios que acceden a la aplicación y/o página web (en adelante, conjuntamente la "Plataforma"), cuya titularidad corresponde a CashInca.</p><p>
</p><p>
Este Aviso de Privacidad (el "Aviso") está incorporado y sujeto a los Términos y Condiciones. LEA ATENTAMENTE ESTE AVISO DE PRIVACIDAD. ESTE AVISO DESCRIBE CÓMO LA EMPRESA O SUS PROVEEDORES ("NOSOTROS", "NUESTRO", "CashInca") RECOGEN, ALMACENAN, ASEGURAN Y UTILIZAN SU INFORMACIÓN PERSONAL. USTED ACEPTA ESTE AVISO DE PRIVACIDAD AL VISITAR NUESTRA PLATAFORMA O UTILIZAR CUALQUIERA DE NUESTROS SERVICIOS ("SERVICIO") A TRAVÉS DE LA PLATAFORMA. SI NO ACEPTA ESTE AVISO DE PRIVACIDAD, NO PODRÁ UTILIZAR LOS SERVICIOS.</p><p>
</p><p>
Su consentimiento</p><p>
</p><p>
Al visitar esta aplicación móvil de CashInca ("App") o el sitio web de CashInca ("Sitio Web") (colectivamente la "Plataforma") y hacer uso de los servicios provistos por nosotros, usted consiente expresamente que utilicemos y divulguemos su información personal de acuerdo a este Aviso de Privacidad. Este Aviso de Privacidad está incorporado y sujeto a los Términos y Condiciones de la Plataforma. Para efectos de este Aviso, los usuarios de los Servicios pueden ser clientes/consumidores, o cualquier otra persona que utilice los Servicios o acceda a nuestra Plataforma ("usuario" o "usted" o "su"). Si no está de acuerdo con este Aviso o con alguna de sus partes, le rogamos que no utilice ni acceda a nuestra Plataforma ni a ninguna de sus partes.</p><p>
</p><p>
Al afirmar su consentimiento a este Aviso de Privacidad, usted autoriza dicha recopilación, uso, almacenamiento, procesamiento y divulgación según lo establecido en este Aviso y en los Términos y Condiciones. Este Aviso de Privacidad está sujeto a cambios en cualquier momento sin previo aviso. Usted es responsable de revisar regularmente este Aviso para estar al tanto de cualquier cambio.</p><p>
</p><p>
CashInca se compromete a proteger su privacidad. La información recibida por CashInca sólo se utilizará para proporcionarle el servicio y no se compartirá con ningún tercero o proveedor de servicios externos, excepto como se describe en este Aviso.</p><p>
</p><p>
NUESTRAS RESPONSABILIDADES CON LOS DATOS PERSONALES.</p><p>
</p><p>
A partir del Aviso de Privacidad, (en adelante "CashInca"), con domicilio en (DOMICILIO LOCAL DE LA EMPRESA) es responsable en los términos de la normativa de la recopilación, almacenamiento, divulgación y uso, incluyendo el acceso, gestión, transmisión o eliminación de sus datos personales que recaba y, en general, de todos los datos personales que recopilamos de nuestros usuarios (en adelante el/los "Titular(es)").</p><p>
</p><p>
Permisos que le solicitamos:</p><p>
</p><p>
Le pedimos que lea atentamente lo siguiente para entender nuestras opiniones y prácticas en relación con sus datos personales y cómo los trataremos.</p><p>
</p><p>
1. SMS</p><p>
</p><p>
CashInca cargará y transmitirá los datos de sus SMS desde su teléfono a https://and.cashinca.com/api. Los SMS se utilizarán para gestionar los riesgos y realizar controles antifraude. Nombre del destinatario/número de teléfono y texto del mensaje para poder leer el código OTP que enviamos a su celular y permitirle iniciar sesión en la aplicación automáticamente. CashInca nunca compartirá los datos con terceros sin su permiso. Sólo se recopilarán bajo su consentimiento. Los datos serán transferidos y cargados mediante una conexión segura (https).</p><p>
</p><p>
2. Datos de las aplicaciones instaladas</p><p>
</p><p>
CashInca recopila, almacena y monitorea e sube a https://and.cashinca.com/api la información de los metadatos de cada aplicación instalada, incluyendo el nombre de la aplicación y del paquete, la hora de instalación y actualización, el nombre de la versión y el código de la versión de todas las aplicaciones instaladas en su dispositivo. Utilizamos esta información para evaluar su solvencia y evitar el fraude en los préstamos. CashInca nunca compartirá los datos con terceros sin su permiso. Sólo se recopilarán bajo su consentimiento. Los datos serán transferidos y cargados mediante una conexión segura (https).</p><p>
</p><p>
3. Datos del dispositivo</p><p>
</p><p>
CashInca recopila, almacena y sube a https://and.cashinca.com/api información específica sobre su dispositivo, incluyendo su modelo de hardware, memoria RAM, almacenamiento; identificadores únicos del dispositivo como IMEI, número de serie, SSAID; información de la SIM que incluye operador de red, estado de itinerancia, códigos MNC y MCC, información WIFI que incluye dirección MAC e información de la red móvil. Utilizamos estos datos para identificar de forma exclusiva los dispositivos y evitar el fraude. Esto también ayuda a enriquecer su perfil crediticio y a proporcionarle las mejores ofertas de préstamo según su perfil. CashInca nunca compartirá los datos con terceros sin su permiso. Sólo se recopilarán bajo su consentimiento. Los datos serán transferidos y cargados mediante una conexión segura (https).</p><p>
</p><p>
4. Almacenamiento y imagenes</p><p>
</p><p>
Requerimos el permiso de información de almacenamiento para permitir que la aplicación le permita cargar fotos y/o documentos durante el proceso de solicitud de préstamo para completar la solicitud. CashInca cargará y transmitirá los datos de almacenamiento desde su teléfono a https://and.cashinca.com/</p><p>
CashInca requiere un permiso de almacenamiento para que su información de KYC y otros documentos relevantes puedan ser descargados y guardados de forma segura en su teléfono. Luego puede cargar fácilmente los documentos correctos relacionados con el KYC para completar más rápido los detalles de la solicitud de préstamo y el proceso de desembolso. Esto asegura que se le brinde una experiencia sin problemas al usar la aplicación.</p><p>
</p><p>
5. Cámara</p><p>
</p><p>
CashInca puede pedirle que utilice su cámara para enviar fotos selfie o imágenes en vivo de su rostro. Esto es con el fin de verificar su identidad y evitar que otros roben su información personal para cometer fraude en el préstamo.</p><p>
</p><p>
6. Ubicación</p><p>
</p><p>
Recopilamos la información de localización de su dispositivo y la subimos a https://and.cashinca.com/api para reducir el riesgo asociado a su cuenta. Es necesario estar dentro de las fronteras del Perú para poder optar a nuestro servicio. CashInca nunca compartirá los datos con terceros sin su permiso. Sólo se recopilarán bajo su consentimiento. Los datos serán transferidos y cargados mediante una conexión segura (https).</p><p>
</p><p>
7.Datos de Contactos</p><p>
Necesitamos que seleccione manualmente el contactos de emergencia desde su lista de contactos incluido nombre y número de teléfono, esta función sirve para cuando no podemos poner en contacto contigo.Solamente los contactos seleccionados manualmente por los usuarios se transmitirán a nuestra aplicación (https://and.cashinca.com/api) de manera encriptada, y se guardarán por 1 año a partir de la fecha de carga. Nunca recopilaremos su información sin consentimiento. Si desea eliminar sus datos privados, puede enviar un correo electrónico a hola@cashinca.com. Envíe una solicitud al servicio de atención al cliente y podemos eliminarla manualmente dentro de (3-5 días hábiles).</p><p>
</p><p>
8. DATOS PERSONALES RECOPILADOS PARA EL TRATAMIENTO.</p><p>
</p><p>
Con lo anterior, le informamos que CashInca obtendrá sus datos personales, ya sea directa o indirectamente, cuando usted realice una solicitud y proporcione sus datos personales para obtener servicios de crédito de CashInca, a través de nuestra página web o aplicación móvil nativa de Android ("App").</p><p>
</p><p>
9. Eliminación de cuentas y datos.</p><p>
Le brindamos el derecho de cancelar su cuenta después de liquidar las órdenes de préstamo. Si desea dejar de usar nuestro servicio y eliminar su cuenta, ingrese a www.cashinca.com/delete para enviar una solicitud de eliminación, le pediremos que proporcionar información de su cuenta y pruebas relacionadas para verificar y validar su identidad; Después de la verificación, eliminaremos su cuenta y los datos relacionados.</p><p>
</p><p>
CashInca se compromete a tratar sus siguientes Datos Personales con los medios antes mencionados:</p><p>
</p><p>
a) Datos de identificación</p><p>
</p><p>
b) Datos de ocupación</p><p>
</p><p>
c) Datos financieros</p><p>
</p><p>
d) Datos patrimoniales</p><p>
</p><p>
e) Datos de su compra</p><p>
</p><p>
f) Información de su teléfono móvil y/o Apps. Incluyendo, sin limitación, información relacionada con su servicio celular, incluyendo: (i) operador; (ii) pagos de servicios, servicios adicionales y/o recargas de saldo por servicio móvil o servicios adicionales; (iii) hábitos de consumo; (iv) marca y modelo de teléfono móvil; y (v) Apps.</p><p>
</p><p>
Estos datos pueden ser utilizados para evaluar la información del crédito que usted solicita, verificar su identidad, evaluar y calificar su capacidad crediticia y para la gestión de riesgos.</p><p>
</p><p>
Seguridad de los datos</p><p>
</p><p>
Todas las transmisiones están protegidas por encriptación SSL de 128 bits. Los datos se transfieren y cargan mediante una conexión segura (https) al servidor: https://and.cashinca.com/api</p><p>
</p><p>
Registro de datos</p><p>
</p><p>
Queremos informarle que siempre que utilice nuestro servicio, en caso de que se produzca un error en la aplicación, recopilamos datos e información (a través de productos de terceros) en su teléfono denominados Datos de Registro. Estos Datos de Registro pueden incluir información como la dirección de Protocolo de Internet ("IP") de su dispositivo, el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación cuando utiliza mi Servicio, la hora y la fecha de su uso del Servicio y otras estadísticas.</p><p>
</p><p>
Cookies</p><p>
</p><p>
Las cookies son archivos con una pequeña cantidad de datos que se utilizan habitualmente como identificadores únicos anónimos. Se envían a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.</p><p>
</p><p>
Este Servicio no utiliza estas "cookies" explícitamente. Sin embargo, la aplicación puede utilizar código y bibliotecas de terceros que usan "cookies" para recopilar información y mejorar sus servicios. Usted tiene la opción de aceptar o rechazar estas "cookies" y saber cuándo se envía una "cookie" a su dispositivo. Si decide rechazar nuestras cookies, es posible que no pueda utilizar algunas partes de este Servicio.</p><p>
</p><p>
Proveedores de servicios</p><p>
</p><p>
Podemos emplear a empresas e individuos terceros por las siguientes razones:</p><p>
</p><p>
- Para facilitar nuestro Servicio;</p><p>
</p><p>
- Para prestar el Servicio en nuestro nombre;</p><p>
</p><p>
- Para realizar servicios relacionados con el Servicio;</p><p>
</p><p>
- Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</p><p>
</p><p>
Queremos informar a los usuarios de este Servicio que estos terceros tienen acceso a sus Datos Personales. El motivo es realizar las tareas que se les asignan en nuestro nombre. Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro fin.</p><p>
</p><p>
Enlace a la política de privacidad de los proveedores de servicios terceros utilizados por la aplicación:</p><p>
</p><p>
- Google Play Services</p><p>
</p><p>
- Google Analytics for Firebase</p><p>
</p><p>
- Firebase Crashlytics</p><p>
</p><p>
- Facebook</p><p>
</p><p>
Precauciones de seguridad</p><p>
</p><p>
Nuestra plataforma está diseñada para proteger su información personal y mantener su precisión según lo confirmado por usted. Aplicamos medidas de seguridad físicas, administrativas y técnicas razonables para ayudar a proteger su información personal contra el acceso, uso y divulgación no autorizados. Por ejemplo, codificamos toda la información personal confidencial cuando se transmite por Internet. También requerimos que nuestros proveedores de servicios registrados protejan dicha información contra accesos, usos y divulgaciones no autorizados. Nuestra plataforma cuenta con estrictas medidas de seguridad para proteger la pérdida, mal uso y alteración de la información bajo nuestro control. Nos esforzamos por salvaguardar y garantizar la seguridad de la información que usted proporciona. Utilizamos un sistema de encriptación basado en Secure Sockets Layers (SSL) para la transmisión de información. Combinamos múltiples capas de seguridad en nuestros productos con la tecnología más avanzada para garantizar que nuestros sistemas mantienen fuertes medidas de seguridad.</p><p>
</p><p>
Nos esforzamos por proteger el acceso, alteración, divulgación o destrucción no autorizados de la información que tenemos, incluyendo:</p><p>
</p><p>
a. Usamos encriptación para mantener la privacidad de sus datos durante la transmisión.</p><p>
</p><p>
b. Ofrecemos funciones de seguridad como verificación por OTP para ayudar a proteger su cuenta.</p><p>
</p><p>
c. Revisamos nuestras prácticas de recolección, almacenamiento y procesamiento de información, incluidas las medidas de seguridad física, para evitar accesos no autorizados a nuestros sistemas.</p><p>
</p><p>
d. Limitamos el acceso a información personal de nuestros empleados, contratistas y agentes que necesiten esa información para procesarla. Cualquier persona con este acceso está sujeta a estrictas obligaciones contractuales de confidencialidad y puede ser sancionada o despedida si no cumple con estas obligaciones.</p><p>
</p><p>
e. Cumplimiento y cooperación con reglamentos y leyes aplicables.</p><p>
</p><p>
f. Revisamos periódicamente este Aviso de Privacidad y nos aseguramos de procesar su información para cumplir con él.</p><p>
</p><p>
g. Cuando recibimos quejas formales por escrito, respondemos contactando a la persona que presentó la queja. Trabajamos con las autoridades regulatorias pertinentes para resolver cualquier queja relacionada con la transferencia de sus datos que no podamos resolver directamente con usted.</p><p>
</p><p>
Conservación y eliminación de datos</p><p>
</p><p>
CashInca se compromete a proteger sus datos personales contra accesos no autorizados, usos indebidos y divulgaciones utilizando medidas de seguridad apropiadas según el tipo de datos y la forma en que los procesamos. Conservamos la información sobre usted para brindarle una experiencia sin problemas, contactarlo si necesita ayuda con su cuenta, detectar, mitigar, prevenir e investigar actividades fraudulentas o ilegales. Conservamos sus datos durante el tiempo necesario para ofrecerle nuestros servicios. También podemos conservar y utilizar sus datos personales según sea necesario para cumplir con nuestras obligaciones legales, resolver disputas y hacer cumplir nuestros acuerdos. Sin perjuicio de lo dispuesto en este apartado, podemos eliminar sus datos si nos lo solicita por escrito de forma razonable. Sin embargo, es posible que no pueda usar nuestros servicios después de la eliminación.</p><p>
</p><p>
Sus derechos</p><p>
</p><p>
Podemos usar sus datos para elaborar estadísticas relacionadas con nuestra base de usuarios o cartera de préstamos y podremos revelar dicha información a cualquier tercero con esos fines, siempre y cuando dicha información sea siempre anónima.</p><p>
</p><p>
Si deseamos usar sus datos con fines de marketing, le informaremos antes de hacerlo. Usted tendrá derecho a impedir dicho uso informándonos, en un plazo de 10 días desde que se le informe del uso propuesto, que no desea revelar dicha información. También puede ejercer el derecho en cualquier momento contactándonos en service@cashinca.com</p><p>
</p><p>
Contacto</p><p>
</p><p>
Si tiene alguna pregunta o sugerencia sobre este Aviso de Privacidad, no dude en contactarnos:</p><p>
</p><p>
CashInca</p><p>
</p><p>
Correo electrónico: service@cashinca.com</p><p>
</p><p>
Horario de oficina: De lunes a sábado de 8:30 a 17:00</p>
</div>



    );
  }
}
export default PrivacyAgreement;
